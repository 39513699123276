<template>
  <div>
    <Table
      v-loading="requestsLoading"
      custom-class="requests-table"
      :data="tableData"
      :columns="columns"
      :active-sort="activeSort"
      show-index
      rounded
      border
      :hover="false"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageLimit * (currentPage - 1) }}
      </template>
      <template #[`cell-${requestStatusTime}`]="{ rowData: { request, comment } }">
        <div class="d-flex">
          <RequestTag :request="request" @update-request="handleRequestUpdate" />
          <div class="position-relative">
            <div
              :id="request.id"
              class="truncated-text"
              :class="{ expandableComment: isExpandable(request.id, comment) }"
            >
              <div v-for="(line, index) in comment" :key="index">
                {{ line }}
                <br />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #cell-details="{ rowData: { request, details, template, rejectReason, visibleToSupplier } }">
        <div class="position-relative">
          <div
            :id="request.id"
            class="truncated-text m-10000"
            :class="{ expandable: isExpandable(request.id, details) }"
          >
            <div v-for="(line, index) in details" :key="index">
              <span v-if="index === 0">
                <el-tooltip
                  v-if="withVisibilityEye && visibleToSupplier"
                  :key="`eye${index}`"
                  :content="$t('requestsSupplier.table.tags.tooltip.customerVisible')"
                  placement="top"
                  class="ms-1"
                  effect="dark"
                >
                  <ShowIcon class="ms-1" />
                </el-tooltip>
                <el-tooltip
                  v-if="rejectReason"
                  :key="`reason${index}`"
                  :content="rejectReason"
                  placement="top"
                  class="ms-1"
                  effect="dark"
                >
                  <Tag size="mini" type="danger">
                    {{ $t('requestsSupplier.table.tags.name.invalid') }}
                  </Tag>
                </el-tooltip>
                <Tag v-if="template" size="mini">
                  {{ $t(`requestsSupplier.table.tags.name.${template}`) }}
                </Tag>
              </span>
              {{ line }}
              <br />
            </div>
          </div>
        </div>
      </template>
      <template #[`cell-${TABLE_HEADERS.RELATED_TASK}`]="{ rowData: { task } }">
        <Button type="link" class="fw-normal p-0 text-typography-primary" @click.stop="openTask(task)">
          {{ $t(`requests.table.taskNames.${task.type}`) }}
        </Button>
      </template>
      <template #[`cell-${TABLE_HEADERS.SOURCE}`]="{ rowData: { sourceBusiness, tenantId } }">
        <BusinessNameWithPopover :business="sourceBusiness" :tenant-id="tenantId" />
      </template>
      <template #[`cell-${TABLE_HEADERS.TARGET}`]="{ rowData: { targetBusiness, tenantId } }">
        <BusinessNameWithPopover :business="targetBusiness" :tenant-id="tenantId" />
      </template>
      <template #filter-target>
        <div>
          <el-select
            v-model="targetBusinessIdFilter"
            filterable
            clearable
            :popper-append-to-body="false"
            :placeholder="$t('commons.searchWithDots')"
          >
            <el-option v-for="business in businesses" :key="business.id" :label="business.name" :value="business.id">
            </el-option>
          </el-select>
        </div>
      </template>
      <template #filter-source>
        <el-select
          v-model="sourceBusinessIdFilter"
          filterable
          clearable
          :popper-append-to-body="false"
          :placeholder="$t('commons.searchWithDots')"
        >
          <el-option v-for="business in businesses" :key="business.id" :label="business.name" :value="business.id">
          </el-option>
        </el-select>
      </template>
      <template #cell-actions="{ rowIndex }">
        <el-dropdown
          class="d-flex justify-content-center"
          trigger="click"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
          @command="(command) => handleAction(command, rowIndex)"
        >
          <Button
            :id="`actions-row-${rowIndex}`"
            type="icon"
            class="actions-btn text-typography-primary"
            :class="{ active: activeRowActionsIndex === rowIndex }"
          >
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item v-if="withCloseAction" :command="ACTIONS.CLOSE">
              <div class="d-flex align-items-center gap-2 ps-2">
                <CheckCircleIcon />
                <p>{{ $t('requests.actions.close') }}</p>
              </div>
            </el-dropdown-item>
            <el-dropdown-item v-if="withResponseAction" :command="ACTIONS.RESPONSE">
              <div class="d-flex align-items-center gap-2 ps-2">
                <ReplyIcon />
                <p>{{ $t('requests.actions.response') }}</p>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <div class="stylelessDiv"></div>
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';

import { Table, Button, Tag } from '@/modules/core';
import { KebabIcon, CheckCircleIcon, ReplyIcon, ShowIcon } from '@/assets/icons';

import RequestTag from './RequestTag';
import BusinessNameWithPopover from './BusinessNameWithPopover';
import { useBusinessRelation } from '../compositions/useBusinessRelation';

const TASK_TYPE = {
  SUPPLIER_CREATION: 'supplierCreation',
  HANDLE_RECONCILIATION: 'handleReconciliation',
  BALANCE_ALIGNMENT: 'balanceAlignment',
  UNCERTAIN_BILLING: 'uncertainBilling',
  DECLARE: 'declare',
  UPLOAD: 'upload',
  RECORD: 'record',
  ALLOCATION_NUMBER: 'allocation',
};

const TABLE_HEADERS = {
  REQUEST_STATUS: 'requestStatus',
  OPENED_BEFORE: 'openedBefore',
  CLOSED_BEFORE: 'closedBefore',
  RESPONSE_CREATED_AT: 'responseCreatedAt',
  REQUEST: 'request',
  TARGET: 'target',
  SOURCE: 'source',
  RELATED_TASK: 'relatedTask',
  ACTIONS: 'actions',
};

const ACTIONS = {
  CLOSE: 'close',
  RESPONSE: 'response',
};

const TEMPLATES_VISIBLE_TO_SUPPLIER = [
  'balanceAlignment',
  'balanceAlignmentReconciliationStatement',
  'balanceAlignmentMissingDocuments',
];

export default {
  components: {
    Table,
    RequestTag,
    Tag,
    Button,
    BusinessNameWithPopover,
    KebabIcon,
    CheckCircleIcon,
    ReplyIcon,
    ShowIcon,
  },
  props: {
    withCloseAction: { type: Boolean, default: false },
    withResponseAction: { type: Boolean, default: false },
    withVisibilityEye: { type: Boolean, default: false },
    requestsLoading: { type: Boolean, default: false },
    requests: { type: Array, required: true },
    currentPage: { type: Number, required: true },
    pageLimit: { type: Number, required: true },
    businesses: { type: Array, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const targetBusinessIdFilter = computed({
      get: () => root.$route.query.targetBusinessId,
      set: (value) =>
        root.$router.replace({ ...root.$route, query: { ...root.$route.query, targetBusinessId: value || undefined } }),
    });
    const sourceBusinessIdFilter = computed({
      get: () => root.$route.query.sourceBusinessId,
      set: (value) =>
        root.$router.replace({ ...root.$route, query: { ...root.$route.query, sourceBusinessId: value || undefined } }),
    });
    const isClosedRequests = computed(() => props.requests[0]?.closed);
    const isInReviewRequests = computed(() => !props.requests[0]?.isAwaitingResponse && !props.requests[0]?.closed);

    const { businessesClarityWorksWith, loading: businessRelationLoading } = useBusinessRelation();

    return {
      TABLE_HEADERS,
      ACTIONS,
      sourceBusinessIdFilter,
      targetBusinessIdFilter,
      activeRowActionsIndex: ref(-1),
      activeSort: ref({
        direction: -1,
        columnKey: computed(() =>
          isClosedRequests.value
            ? TABLE_HEADERS.CLOSED_BEFORE
            : isInReviewRequests.value
            ? TABLE_HEADERS.RESPONSE_CREATED_AT
            : TABLE_HEADERS.OPENED_BEFORE
        ),
      }),
      businessesClarityWorksWith,
      businessRelationLoading,
      root,
      isClosedRequests,
      isInReviewRequests,
    };
  },
  computed: {
    requestStatusTime() {
      return this.isClosedRequests
        ? TABLE_HEADERS.CLOSED_BEFORE
        : this.isInReviewRequests
        ? TABLE_HEADERS.RESPONSE_CREATED_AT
        : TABLE_HEADERS.OPENED_BEFORE;
    },
    columns() {
      const cols = [
        {
          header: this.$t(`requests.table.headers.${TABLE_HEADERS.REQUEST_STATUS}`),
          key: this.requestStatusTime,
          minWidth: '19rem',
          sortCallback: (direction) => {
            this.activeSort = {
              direction,
              columnKey: this.requestStatusTime,
            };
            this.root.$router.replace({
              ...this.root.$route,
              query: {
                ...this.root.$route.query,
                orderBy: direction,
                sortBy: this.isClosedRequests ? 'closedAt' : this.isInReviewRequests ? 'responseCreatedAt' : 'activeAt',
              },
            });
          },
        },

        {
          header: this.$t(`requests.table.headers.${TABLE_HEADERS.REQUEST}`),
          key: 'details',
          width: '31rem',
        },
        {
          header: this.$t(`requests.table.headers.${TABLE_HEADERS.TARGET}`),
          key: TABLE_HEADERS.TARGET,
          width: '10rem',
          filterActive: !!this.targetBusinessIdFilter,
        },
        {
          header: this.$t(`requests.table.headers.${TABLE_HEADERS.SOURCE}`),
          key: TABLE_HEADERS.SOURCE,
          width: '10rem',
          filterActive: !!this.sourceBusinessIdFilter,
        },
        {
          header: this.$t(`requests.table.headers.${TABLE_HEADERS.RELATED_TASK}`),
          key: TABLE_HEADERS.RELATED_TASK,
          width: '12rem',
        },
      ];
      if (this.withCloseAction || this.withResponseAction) {
        cols.push({
          header: '',
          key: TABLE_HEADERS.ACTIONS,
          width: '2.5rem',
          customClass: 'p-0',
        });
      }
      return cols;
    },
    tableData() {
      return this.requests.map((request) => {
        const {
          closedAt,
          details,
          sourceBusiness,
          targetBusiness,
          targetBusinessId,
          task,
          comment,
          responses,
          template,
          activeAt,
          isAwaitingResponse,
        } = request;
        const formattedDetails = details ? details.split('\n') : [];
        const formattedComment = comment ? comment.split('\n') : [];
        const lastResponse = (responses || []).at(-1);

        const visibleToSupplier =
          TEMPLATES_VISIBLE_TO_SUPPLIER.includes(template) &&
          this.businessesClarityWorksWith.includes(targetBusinessId);

        return {
          details: formattedDetails,
          sourceBusiness,
          targetBusiness,
          task,
          comment: formattedComment,
          request,
          tenantId: task.businessId,
          rejectReason: lastResponse?.reject?.text,
          template: task.type === 'balanceAlignment' ? task.type : null,
          visibleToSupplier,
          closedBefore: closedAt,
          responseCreatedAt: !isAwaitingResponse && !closedAt && lastResponse?.activeAt,
          openedBefore: activeAt,
        };
      });
    },
  },
  methods: {
    actionsVisibleChange(index, isVisible) {
      this.activeRowActionsIndex = isVisible ? index : -1;
    },
    handleRequestUpdate(updateData) {
      this.$emit('update-request', updateData);
    },
    getTaskRouteName(taskType) {
      switch (taskType) {
        case TASK_TYPE.SUPPLIER_CREATION:
          return 'supplierTasks';
        case TASK_TYPE.HANDLE_RECONCILIATION:
          return 'reconciliationTask';
        case TASK_TYPE.UNCERTAIN_BILLING:
          return 'uncertainBillingTask';
        case TASK_TYPE.UPLOAD:
          return 'classifyDocuments';
        case TASK_TYPE.RECORD:
          return 'recordDocuments';
        default:
          return '';
      }
    },
    openTask(task) {
      if (
        [
          TASK_TYPE.DECLARE,
          TASK_TYPE.BALANCE_ALIGNMENT,
          TASK_TYPE.HANDLE_RECONCILIATION,
          TASK_TYPE.UNCERTAIN_BILLING,
          TASK_TYPE.ALLOCATION_NUMBER,
        ].includes(task.type)
      ) {
        const taskUrl = this.$router.resolve({
          name: 'clarity.tasks',
          query: { task: task.id },
        });
        window.open(taskUrl.href, '_blank');
      } else {
        const taskUrl = this.$router.resolve({
          name: this.getTaskRouteName(task.type),
          params: { tenantId: task.businessId, taskId: task.id },
        });
        window.open(taskUrl.href, '_blank');
      }
    },
    handleAction(command, requestIndex) {
      switch (command) {
        case ACTIONS.CLOSE:
          this.$emit('show-close-request-modal', requestIndex);
          break;
        case ACTIONS.RESPONSE:
          this.$emit('show-response-request-modal', requestIndex);
          break;
        default:
          break;
      }
    },
    isExpandable(id, requestComment) {
      const div = document.querySelector('.stylelessDiv');
      const element = document.createElement('div');
      element.id = `temporary-${id}`;
      element.style.width = '31rem';
      element.style.overflowWrap = 'break-word';
      element.textContent = requestComment;
      div.appendChild(element);
      const temporary = document.getElementById(`temporary-${id}`);
      const clientHeight = temporary.clientHeight;
      div.removeChild(element);
      return clientHeight > 20;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep {
  .el-select-dropdown.el-popper {
    width: 250px;
  }
  .table-responsive {
    overflow: unset;
  }
}

.requests-table {
  .actions-btn {
    &.active {
      visibility: visible;
    }
  }
}

tr {
  .actions-btn {
    visibility: hidden;
  }

  &:hover .actions-btn {
    visibility: visible;
  }
}

.truncated-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  word-break: break-word;
}

.expandable:hover,
.expandableComment:hover {
  -webkit-line-clamp: unset;
  line-clamp: unset;
  display: inline-block;
  padding: 0.5rem;
  background: #fff;
  position: absolute;
  border-radius: 6px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: -1rem;
  cursor: pointer;
}

.expandable:hover {
  width: 31rem;
}

.expandableComment:hover {
  width: 20rem;
}
</style>
